import $ from 'jquery';
import 'bootstrap';
import 'jquery-validation'

function init() {
    function toggleAgreement()
    {
        $('#CheckAgreementBlock').hide();
        $('#opt-checkboxes input[type=checkbox]').each(function () {
            if ($(this).is(':checked')) {
                $('#CheckAgreementBlock').show();
                return;
            }
        });
    }
    
    $('body').addClass('page-loaded');

    $('#opttoggle a').click(function (e) {
        e.preventDefault();

        var checkboxesdiv = $(this).parent().parent();
        var checkboxes = checkboxesdiv.find('input:checkbox');

        checkboxes.each(function (index) {
            if (checkboxesdiv.hasClass('optout')) {
                checkboxes[index].checked = true;
            } else {
                checkboxes[index].checked = false;
            }
        });

        checkboxesdiv.toggleClass('optin').toggleClass('optout');

        toggleAgreement()
    });

    $('#opt-checkboxes input[type=checkbox]').click(function () {
        toggleAgreement();
    });

    $(document).ready(function () {
        toggleAgreement();

        $('form').one('submit', function () {
            console.log("test");
            $(this).find('input[type="submit"]').attr('disabled', 'disabled');
        });
    });

    if ($("#PersonalDataNote3").length) {
        $("#mail-formgroup label").html($("#mail-formgroup label").html() + "***");
    }
}

export default {
    init
};
